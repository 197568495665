const { api } = useApi()

const loading = ref({
  notifications: false,
  error: false,
})

export default function useNotification() {
  const { fetchUserInfo } = useUser()
  const { notifications, hasNotification } = storeToRefs(useNotificationStore())
  const { logError } = useSentry()

  async function fetchNotifications() {
    try {
      loading.value.notifications = true
      loading.value.error = false
      const response = await api.notification.fetchNotifications()
      if (response) {
        notifications.value = response.data
      }
      loading.value.notifications = false
    } catch (err: any) {
      loading.value.notifications = false
      loading.value.error = true
      logError(err)
    }
  }
  async function updateNotificationStatus() {
    try {
      hasNotification.value = false
      const response = await api.notification.updateNotificationStatus({
        isNotification: false,
      })
      if (response.success) {
        fetchUserInfo()
      }
    } catch (err) {
      hasNotification.value = false
      logError(err)
    }
  }

  return {
    loading,
    fetchNotifications,
    updateNotificationStatus,
  }
}
